import React, { Fragment } from "react";
import { BrowserRouter as Router, Route, Routes as RTS } from "react-router-dom";
import HomePage from "../src/pages/HomePage";
import Routes from "../src/routers/Routes";
import Navbar from "../src/components/Navbar";
import Footer from "../src/components/Footer";

// AntUI
import { Layout } from "antd";

//Amplify Auth
import { withAuthenticator } from "@aws-amplify/ui-react";

const App = () => (
  <Router>
    <Fragment>
      <Layout className="layout" style={styles.layout}>
        <Navbar />
        <RTS>
          <Route exact path="/" component={HomePage} />
          <Route component={Routes} />
        </RTS>
        <Footer />
      </Layout>
    </Fragment>
  </Router>
);

const styles = {
  layout: {
    minHeight: "100vh"
  }
};
export default withAuthenticator(App);
